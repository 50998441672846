import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VToolbar,{attrs:{"app":""}},[_c(VToolbarTitle,{staticClass:"headline"},[_c('span',[_vm._v("AndyLeTourneau.com")])]),_c(VSpacer),_c(VBtn,{attrs:{"flat":"","href":"https://github.com/centerorbit","target":"_blank"}},[_c('span',{staticClass:"mr-2",staticStyle:{"margin-right":"12px"}},[_vm._v("See me on GitHub")]),_c(VAvatar,{attrs:{"size":"32px","color":"grey lighten-4"}},[_c('img',{attrs:{"src":require('../assets/github/GitHub-Mark-64px.png'),"alt":"GitHub"}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }