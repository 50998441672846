import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"grid-list-md":"","text-xs-center":""}},[_c(VLayout,{staticClass:"flippit",attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"sm8":""}},[_c(VCard,{staticClass:"today",attrs:{"light":""}},[_c(VList,{attrs:{"two-line":""}},[_c(VSubheader,[_vm._v(" Today ")]),_vm._l((_vm.items),function(item,index){return [(item.divider)?_c(VDivider,{key:index,attrs:{"inset":item.inset}}):_c(VListTile,{key:item.title,attrs:{"avatar":""}},[_c(VListTileAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListTileContent,[_c(VListTileTitle,{domProps:{"innerHTML":_vm._s(item.title)}}),_c(VListTileSubTitle,{domProps:{"innerHTML":_vm._s(item.subtitle)}})],1)],1)]})],2)],1)],1),_c(VFlex,{attrs:{"sm4":""}},[_c(VCard,{staticClass:"pic",attrs:{"light":""}},[_c(VAvatar,{staticStyle:{"margin-top":"-80px","margin-bottom":"12px"},attrs:{"size":"200px","color":"grey lighten-4"}},[_c('img',{staticClass:"elevation-8",attrs:{"src":require('../assets/tesla.jpg'),"alt":"avatar"}})]),_c(VCardText,{staticClass:"px-0"},[_c('h4',{staticClass:"card-title"},[_vm._v("Andrew LeTourneau")]),_c('h6',{staticClass:"category text-gray font-weight-light mb-3"},[_vm._v("Principal Software Engineer")]),_c('p',{staticClass:"card-description"},[_vm._v("A forward thinking and curious engineer with a focus towards efficiency and automation.")])]),_c('img',{attrs:{"src":"https://img.shields.io/badge/Status-Healthy-green"}}),_vm._v("   "),_c('img',{attrs:{"src":"https://img.shields.io/badge/Build-Passing-green"}})],1)],1)],1),_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VCard,{attrs:{"dark":"","color":"primary"}},[_c(VCardText,{staticClass:"px-0"},[_vm._v("More about me")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }