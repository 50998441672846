import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VLayout,{staticClass:"flippit",attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"sm6":"","flex":""}},[_c(VCard,[_c(VImg,{attrs:{"src":require('../assets/shiplift.png'),"aspect-ratio":"3","contain":""}}),_c(VCardTitle,{staticClass:"centerorbit-text",attrs:{"primary-title":""}},[_c('div',[_c('h3',{staticClass:"headline mb-0"},[_c('a',{staticClass:"no-link",attrs:{"target":"_blank","href":"https://shiplift.io"}},[_vm._v("ShipLift.io")])]),_c('div',[_c('br'),_c('p',[_vm._v("A small startup that:")]),_c('ul',[_c('li',[_vm._v("Provides consultation and coding services.")]),_c('li',[_vm._v("Develops and contributes to various open-source projects and initiatives.")]),_c('li',[_vm._v("Builds small yet impactful apps.")])])])])]),_c(VCardActions,[_c(VBtn,{attrs:{"href":"mailto:contact@shiplift.io","flat":"","color":"blue"}},[_vm._v("Email")]),_c(VBtn,{attrs:{"href":"https://shiplift.io","target":"_blank","flat":"","color":"teal"}},[_vm._v("Explore")])],1)],1)],1),_c(VFlex,{attrs:{"sm6":"","flex":""}},[_c(VCard,{staticClass:"tools-of-trade",attrs:{"light":""}},[_c(VList,{staticClass:"tools-of-trade",attrs:{"two-line":""}},[_c(VSubheader,[_vm._v(" Initiatives ")]),_vm._l((_vm.items),function(item,index){return [(item.divider)?_c(VDivider,{key:index,attrs:{"inset":item.inset}}):_c(VListTile,{key:item.title,attrs:{"avatar":""}},[_c(VListTileAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListTileContent,[_c(VListTileTitle,{domProps:{"innerHTML":_vm._s(item.title)}}),_c(VListTileSubTitle,{domProps:{"innerHTML":_vm._s(item.subtitle)}})],1)],1)]})],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }