import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import Vuetify from 'vuetify'

import colors from 'vuetify/es5/util/colors'

Vue.config.productionTip = false

Vue.use(Vuetify,{
  theme: {
    primary: colors.lightBlue.base,
    secondary: colors.lightBlue.lighten3,
    accent: colors.lightBlue.lighten5
  },
})

new Vue({
  render: h => h(App),
  theme: {
    primary: colors.lightBlue.base,
    secondary: colors.lightBlue.lighten3,
    accent: colors.lightBlue.lighten5
  },
}).$mount('#app')
