import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContent } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('ToolBar'),_c(VContent,[_c('Summary'),_c('Details'),_c(VFooter,{staticClass:"pa-3",attrs:{"height":"auto","color":"primary lighten-2"}},[_c(VCard,{staticClass:"primary lighten-2 white--text text-xs-center",attrs:{"flat":"","tile":""}},[_c(VCardText,_vm._l((_vm.icons),function(link){return _c(VBtn,{key:link.icon,staticClass:"mx-3 white--text",attrs:{"href":link.href,"icon":""}},[_c(VIcon,{attrs:{"size":"36px"}},[_vm._v(_vm._s(link.icon))])],1)}),1),_c(VCardText,{staticClass:"white--text"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("Andrew LeTourneau")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }