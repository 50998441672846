<template>
  <v-container>
    <v-layout class="flippit" row wrap>
      <v-flex sm6 flex>
        <v-card>
          <v-img :src="require('../assets/shiplift.png')" aspect-ratio="3" contain />
          <v-card-title primary-title class="centerorbit-text">
            <div>
              <h3 class="headline mb-0"><a target='_blank' class="no-link" href="https://shiplift.io">ShipLift.io</a></h3>
              <div>
                <br />
                <p>A small startup that:</p>
                <ul>
                  <li>Provides consultation and coding services.</li>
                  <li>Develops and contributes to various open-source projects and initiatives.</li>
                  <li>Builds small yet impactful apps.</li>
                </ul>
              </div>
            </div>
          </v-card-title>

          <v-card-actions>
            <v-btn href="mailto:contact@shiplift.io" flat color="blue">Email</v-btn>
            <v-btn href="https://shiplift.io" target="_blank" flat color="teal">Explore</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex sm6 flex>
        <v-card class="tools-of-trade" light>
          <v-list two-line class="tools-of-trade">
            <v-subheader>
              Initiatives
            </v-subheader>

            <template v-for="(item, index) in items">
              <v-divider v-if="item.divider" :key="index" :inset="item.inset"></v-divider>

              <v-list-tile v-else :key="item.title" avatar>
                <v-list-tile-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title v-html="item.title"></v-list-tile-title>
                  <v-list-tile-sub-title v-html="item.subtitle"></v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Details",
  data() {
    return {
      items: [
        {
          icon: 'mdi-hammer-wrench',
          title: 'Maker of Things',
          subtitle: "<span class='text--primary'><a class='text--primary no-link' href='https://makerig.com/' target='_blank'>MakeRig</a></span>, " +
            "<a class='no-link' href='https://www.printables.com/social/499100-makerig/about' target='_blank'>Printables</a>, " +
            "<a class='no-link' href='https://www.patreon.com/makerig' target='_blank'>Patreon</a>, " +
            "<a class='no-link' href='https://oddinventor.com/' target='_blank'>Odd Inventor</a>"
        },
        { divider: true, inset: true },
        {
          icon: 'cake',
          title: 'Open Source',
          subtitle: "<span class='text--primary'><a class='text--primary no-link' href='https://defrag.shiplift.dev/' target='_blank'>Defrag</a></span>, " +
            "<a class='no-link' href='https://github.com/ShipLift-LLC/DriveKeys' target='_blank'>DriveKeys</a>, " +
            "<a class='no-link' href='https://github.com/centerorbit/depcharge' target='_blank'>DepCharge</a>, " +
            "<a class='no-link' href='https://github.com/ShipLift-LLC/Intern' target='_blank'>Intern</a>"
        },
        { divider: true, inset: true },
        {
          icon: 'keyboard',
          title: 'Software & DevOps',
          subtitle: "<span class='text--primary'><a class='text--primary no-link' href='https://golang.org/' target='_blank'>Go</a></span>, " +
            "<a class='no-link' href='https://docker.com/' target='_blank'>Docker</a>, " +
            "<a class='no-link' href='https://vuejs.org/' target='_blank'>Vue.js</a>, " +
            "Bash, Ansible, CI/CD & Pipelines"
        },
        { divider: true, inset: true },
        {
          icon: 'mdi-desk-lamp-on',
          title: 'Concepts',
          subtitle: "<span class='text--primary'><a class='text--primary no-link' href='https://photos.app.goo.gl/eSjzGu1CpA7XkZgh7' target='_blank'>Sculptures</a></span>, " +
          "<a class='no-link' href='https://photos.app.goo.gl/N3CHLqgaFodpO4vt2' target='_blank'>Rock Tumbler</a>, " +
          "<a class='no-link' href='https://photos.app.goo.gl/RaVMOXD64PfkVlsv1' target='_blank'>Zen Room</a>, " +
            "<a class='no-link' href='https://photos.app.goo.gl/7RSMNJP1a4U527sN9' target='_blank'>Geo</a>, " +
            "<a class='no-link' href='https://photos.app.goo.gl/u4btEThEA7eF1yXw6' target='_blank'>Reverse Engineering</a>, " +
            "<a class='no-link' href='https://photos.app.goo.gl/5t6f7gsm7teU2hiN8' target='_blank'>e-Walnut</a>"
        },
      ]
    }
  }
}

</script>

<style>
@media screen and (min-width: 1903px) {
  .tools-of-trade {
    padding-bottom: 45px;
  }

  .centerorbit-text {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 1903px) and (min-width: 1264px) {
  .centerorbit-text {
    padding-bottom: 72px;
  }
}

@media screen and (max-width: 1264px) and (min-width: 600px) {
  .centerorbit-text {
    padding-bottom: 103px;
  }
}


a:link,
.no-link {
  color: black;
  text-decoration: none;
}

div.tools-of-trade {
  height: 100%;
}

.flex {
  padding: 4px;
}

div.container {
  align-items: center;
  padding-top: 5px;
  padding-bottom: 10px;
}
</style>
