<template>
  <v-app>
    <ToolBar/>
    <v-content>
      <Summary/>
      <Details/>
      <v-footer height="auto" color="primary lighten-2" class="pa-3">
        <v-card
                flat
                tile
                class="primary lighten-2 white--text text-xs-center"
        >
        <v-card-text>
          <v-btn
                  v-for="link in icons"
                  :key="link.icon"
                  class="mx-3 white--text"
                  :href="link.href"
                  icon
          >
            <v-icon size="36px">{{ link.icon }}</v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text">
          &copy; {{ new Date().getFullYear() }} — <strong>Andrew LeTourneau</strong>
        </v-card-text>
          <!--<v-card-text>-->
            <!--TODO: Make accessible for anon peeps <a class="pipeline" href="https://gitlab.com/centerorbit_productions/sites/andyletourneau.com/commits/master"><img alt="pipeline status" src="https://gitlab.com/centerorbit_productions/sites/andyletourneau.com/badges/master/pipeline.svg" /></a>-->
          <!--</v-card-text>-->
        </v-card>
      </v-footer>
    </v-content>
  </v-app>
</template>
<script>
import Details from './components/Details'
import Summary from './components/Summary'
import ToolBar from './components/ToolBar'

export default {
  name: 'App',
  components: {
    Details,
    Summary,
    ToolBar
  },
  data: () => ({
    icons: [
    {
        icon: 'mdi-ferry',
        href: 'https://shiplift.io'
      },
      {
        icon: 'fab fa-linkedin',
        href: 'https://www.linkedin.com/in/centerorbit'
      },
      {
        icon: 'fab fa-stack-overflow',
        href: 'https://stackoverflow.com/users/663058/centerorbit'
      },
      {
        icon: 'fab fa-gitlab',
        href: 'https://gitlab.com/centerorbit'
      },
      {
        icon: 'fab fa-bitbucket',
        href: 'https://bitbucket.org/centerorbit/'
      },
      {
        icon: 'fab fa-github',
        href: 'https://github.com/centerorbit'
      },
      {
        icon: 'mdi-pier-crane',
        href: 'https://makerig.com'
      }
    ]
  })
}
</script>
<style>
  .pipeline {
    float: right;
    position: relative;
    top: -20px
  }

  footer {
    display: contents !important;
    margin-left: auto;
    margin-right: auto;
  }
</style>
