<template>
  <v-container grid-list-md text-xs-center>
    <v-layout class="flippit" row wrap>
      <v-flex sm8>
        <v-card light class="today">
          <v-list two-line>
            <v-subheader>
              Today
            </v-subheader>

            <template v-for="(item, index) in items">
              <v-divider
                      v-if="item.divider"
                      :key="index"
                      :inset="item.inset"
              ></v-divider>

              <v-list-tile
                      v-else
                      :key="item.title"
                      avatar
              >
                <v-list-tile-action>
                  <v-icon>{{item.icon}}</v-icon>
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title v-html="item.title"></v-list-tile-title>
                  <v-list-tile-sub-title v-html="item.subtitle"></v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex sm4>
        <v-card light class="pic">
          <v-avatar
                  size="200px"
                  color="grey lighten-4"
                  style="margin-top: -80px; margin-bottom: 12px"
          >
            <img class="elevation-8" :src="require('../assets/tesla.jpg')" alt="avatar">
          </v-avatar>
          <v-card-text class="px-0">
            <h4 class="card-title">Andrew LeTourneau</h4>
            <h6 class="category text-gray font-weight-light mb-3">Principal Software Engineer</h6>
            <p class="card-description ">A forward thinking and curious engineer with a focus towards efficiency and automation.</p>
          </v-card-text>
          <img src="https://img.shields.io/badge/Status-Healthy-green" />
          &nbsp;
          <img src="https://img.shields.io/badge/Build-Passing-green" />
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card dark color="primary">
          <v-card-text class="px-0">More about me</v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

  </v-container>
</template>
<script>
  export default {
    name: 'Summary',
    data () {
      return {
        items: [
          {
            icon: 'work',
            title: 'ShipLift LLC',
            subtitle: "Co-Owner / Co-Founder"
          },
          { divider: true, inset: true },
          {
            icon: 'person_pin_circle',
            title: 'Lives in: Minnesota, USA',
            subtitle: "<span class='text--primary'>Stacy</span>, 55079"
          },
          { divider: true, inset: true },
          {
            icon: 'alternate_email',
            title: 'Contact Me',
            subtitle: "<span class='text--primary'><a class='text--primary no-link' href='mailto:andy@shiplift.io'>andy@shiplift.io</a></span>, <a class='no-link' href='tel:651-785-6676'>651-785-6676</a>"
          }
        ]
      }
    }
  }
</script>
<style>
  a:link {
    color: rgba(0,0,0,0.54);
    text-decoration: none;
  }

  .today {
    height: 75%;
  }

  @media screen and (min-width: 1903px) {
    .today {
      margin-top: 100px;
    }
    .pic {
      padding-bottom: 27px;
    }
  }

  @media screen and (max-width: 1903px) and (min-width: 1264px) {
    .today {
      margin-top: 100px;
    }
    .pic {
      padding-bottom: 6px;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1263px){
    .today {
      margin-top: 105px;
    }
  }

  @media screen and (max-width: 600px) {
    .flippit {
      flex-direction: column-reverse !important;
    }
  }
  p.card-description {
    padding: 1px;
  }
  div.grey.lighten-4.v-avatar {
    background-color: transparent !important;
  }
  .pic {
    margin-top: 100px;
  }
  div.centerorbit-text{
    padding-bottom: 0;
  }
</style>
